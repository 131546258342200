.cv_projects {
 background-color: var(--white);
/*  padding: 8.33vh 16.66vw 16.66vh 16.66vw; */
 display: flex;
 flex-direction: row;
 /* gap: 8.33vh; */
 padding-bottom: 8.33vh;
}

.cv_project {
 display: flex;
 flex-direction: column;
 gap: 0;
}

.cv_ava {
 width: 50vmin;
 height: 80vh;
 object-fit: cover;
}

.cv_project_text {
 max-width: var(--font_size_60);
 padding: var(--font_size_2);
}

.cv_project_text p {
 padding-bottom: var(--font_size_2);
}
.cv_date {
 display: flex;
 flex-direction: row;
}

.cv_title_date {
 display: flex;
 flex-direction: column;
 min-width: 180px;
}

hr {
 border: 1px solid var(--action);
}
@media (orientation: portrait) {
 .cv_projects {
  flex-direction: column;
  padding-bottom: var(--size_finger);
  gap: 0;

 }
 .cv_project_text{
  padding: var(--font_size_2);
 }
 .cv_ava{
  width: 100vw;
  height: 60vh;
 }
}