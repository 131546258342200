:root {
 --white: #ffffff;
 --text-primary: #010102;
 --text-tertiary: #5D6672;
 --black: #000000;
 --hover: #d4ff00;
 --active: #d4ff00;
 --action: #FF005C;
 --font_size: 20px;
 --font_size_2: 40px;
 --font_size_60: 740px;
 --size_finger: 72px;
}



* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 font-size: 20px;
 line-height: 1.5;
 font-family: 'Roboto Mono', monospace;
 cursor: url('/public/point.svg'), auto;
}

html {
 background-color: var(--action);
}

.content {
 display: flex;
 flex-direction: column;
 padding-top: 30px;
 cursor: url('/public/point.svg'), auto;
 align-items: center;
}

@media (orientation: portrait) {
 .content {
  padding-top: var(--size_finger);
 }
}

.title {
 font-weight: 700;
}

.text {
 font-weight: 400;
}



a {
 text-decoration: none;
 color: blue;
}

a:hover {
 color: var(--active);
}


@keyframes colorAnimation {
 0% {
  color: rgb(255, 0, 132);
 }

 33% {
  color: blue;
 }

 50% {
  color: rgb(0, 238, 0);
 }

 100% {
  color: rgb(255, 0, 132);
 }
}

input {
 background-color: var(--white);
 color: var(--text-tertiary);
 border: 0;
 width: 100%;
 
}

input:focus {
 outline: none;
 background-color: var(--active);
}

th {
 text-align: left;
}
button {
 border: 0;
}

.buttonAnimation {
 animation: colorAnimation 3s linear infinite;
}

.button:hover {
 background-color: var(--hover);
 color: blue;
}

.button:active {
 background-color: var(--active);
 color: black;
}

.button {
 padding: 0 20px;
 background-color: var(--white);
 cursor: pointer;
 display: flex;
 justify-content: center;
 align-items: center;
}

.buttonActive {
 padding: 0 20px;
 background-color: var(--active);
 display: flex;
 justify-content: center;
 align-items: center;
}

.buttonCV {
 padding: 0 var(--font_size);
 background-color: blue;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
}

.buttonCV:hover {
 background-color: var(--active);
 color: blue;
}

.ava_and_pdf {
 display: flex;
 flex-direction: column;
 gap: 0;
 position: sticky;
 top: 0;
 height: 100vh;
}


@media (orientation: portrait) {
 .button {
  height: var(--size_finger);
 }

 .ava_and_pdf {
  top: calc(-60vh + var(--size_finger));
  height: auto;
 }

 .buttonActive {
  height: var(--size_finger);
 }

 .buttonCV {
  height: var(--size_finger);
  min-width: var(--size_finger);
 }
}


.ava {
 width: 30px;
 animation: rotate 2s linear infinite;
 border-radius: 30px;

}

.avaContainer {
 transform-origin: top left;
 transition: transform 0.3s ease-in-out;
}

.avaContainer:hover {
 transform-origin: top left;
 transform: scale(3);
}

@keyframes rotate {
 0% {
  transform: rotate(0deg);
 }

 100% {
  transform: rotate(360deg);
 }
}

.menuHeader {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 position: fixed;
 z-index: 100;
}

.menuFilter {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 position: fixed;
 z-index: 100;
 right: 0;
}

.gallery2 {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
}

@media (orientation: portrait) {

 .ava {
  width: var(--size_finger);
 }

 .menuFilter {
  bottom: 0;
  left: 0;
  /* right: auto; */
  background-color: white;
 }

 .gallery2 {
  display: flex;
  flex-direction: column;
 }

}