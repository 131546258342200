:root {
 --white: #ffffff;
 --bg_comfort: #f5f5f5;
 --text-primary: #010102;
 --text-tertiary: #5D6672;
 --black: #000000;
 --hover: #d4ff00;
 --active: #d4ff00;
 --action: #FF005C;
 --font_size: 20px;
 --font_size_2: 40px;
 --font_size_60: 740px;
 --size_finger: 72px;
}

/* Общие стили для контейнера */
.container {
 background-color: var(--bg_comfort);
 min-height: 100vh;
 align-content: center;
 align-items: center;
 width: 100%;
}


/* Стили для видео */
.video-container {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 20px;
 margin-top: 20px;
 flex-direction: column;
}

.video {
 width: 640px;
 height: auto;
}


/* time */

span#t {
 color: #000;
}

span#result {
 color: #000;
 font-size: 6vmin;
}

#result_5percent {
 font-weight: bold;
}

link {
 color: #0000ff;
}

::selection {
 background-color: yellow;
}


/* Optimistic: #4CAF50 (зеленый)
Realistic: #2196F3 (синий)
Pessimistic: #FF5252 (красный)

Optimistic: #FFC107 (жёлтый)
Realistic: #FF5722 (оранжевый)
Pessimistic: #9C27B0 (фиолетовый)

Optimistic: #00BCD4 (голубой)
Realistic: #FF9800 (оранжевый)
Pessimistic: #E91E63 (розовый)

#4caf50
#2196f3
#f44336

#1C9E76
#D95F01
#7570B3
*/
:root {
 --optimistic: #1C9E76;
 --realistic: #D95F01;
 --pessimistic: #7570B3;
}
.optimistic {
 color: var(--optimistic);
}

.realistic {
 color: var(--realistic);
}

.pessimistic {
 color: var(--pessimistic);
}

.formula {
 font-size: 18px;
 color: #ccc;
}

.input_time {
 margin-bottom: 1ch;
 /* max-width: 10ch; */
}